import React from 'react';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import logo from '../../assets/images/logo.png';
import logo2x from '../../assets/images/logo@2x.png';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '90vh',
    px: 4,
    width: '100%',
  }),
);

const Logo = styled.img(
  css({
    display: 'block',
    mb: 2,
    mx: 'auto',
  }),
);

const LoggedOutLayout = ({ children }) => (
  <Main>
    <Card width="350px">
      <Card.Body textAlign="center">
        <Logo
          src={logo}
          srcSet={`${logo} 1x, ${logo2x} 2x`}
          alt="American Samoa Departament of Health"
        />
        {children}
      </Card.Body>
    </Card>
  </Main>
);

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
