import React, { lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ScrollToTop from '@bit/medicalwebexperts.mwe-ui.scroll-to-top';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoggedOutLayout from '../Layout/LoggedOutLayout';
import LoggedInLayout from '../Layout/LoggedInLayout';
import PublicRoute from '../Routing/PublicRoute';
import PrivateRoute from '../Routing/PrivateRoute';
import Loader from './Loader';

const LoginPage = lazy(() => import('../../pages/Account/Login/Login'));
const ForgotPasswordPage = lazy(() =>
  import('../../pages/Account/ForgotPassword/ForgotPassword'),
);
const ResetPasswordPage = lazy(() =>
  import('../../pages/Account/ResetPassword/ResetPassword'),
);
const PreferencePage = lazy(() =>
  import('../../pages/Preferences/Preferences'),
);

const UserListPage = lazy(() => import('../../pages/User/List'));
const UserCreatePage = lazy(() => import('../../pages/User/Create'));
const UserEditPage = lazy(() => import('../../pages/User/Edit'));

const TravelDeclarationListPage = lazy(() =>
  import('../../pages/TravelDeclaration/List'),
);
const TravelDeclarationEditPage = lazy(() =>
  import('../../pages/TravelDeclaration/Edit'),
);

const MyTravelDeclarationsListPage = lazy(() =>
  import('../../pages/MyTravelDeclarations/List'),
);
const MyTravelDeclarationsUploadPage = lazy(() =>
  import('../../pages/MyTravelDeclarations/Upload'),
);

const ActivityLogListPage = lazy(() => import('../../pages/ActivityLog/List'));

const NotFoundPage = lazy(() => import('../../pages/404'));

const App = () => (
  <Router>
    <Helmet titleTemplate="%s - American Samoa Departament of Health" />
    <ScrollToTop />
    <ErrorBoundary>
      <Switch>
        <PublicRoute path="/account">
          <LoggedOutLayout>
            <Loader>
              <Switch>
                <Route path="/account/login" exact>
                  <LoginPage />
                </Route>
                <Route path="/account/forgot-password" exact>
                  <ForgotPasswordPage />
                </Route>
                <Route path="/account/reset-password/:uid/:token" exact>
                  <ResetPasswordPage />
                </Route>
                <Redirect to="/account/login" />
              </Switch>
            </Loader>
          </LoggedOutLayout>
        </PublicRoute>

        <PrivateRoute path="/">
          <LoggedInLayout>
            <Loader>
              <Switch>
                <Route path="/preferences">
                  <PreferencePage />
                </Route>

                <Route path="/users">
                  <Switch>
                    <Route path="/users" exact>
                      <UserListPage />
                    </Route>
                    <Route path="/users/create" exact>
                      <UserCreatePage />
                    </Route>
                    <Route path="/users/:id" exact>
                      <UserEditPage />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/travel-declaration">
                  <Switch>
                    <Route path="/travel-declaration" exact>
                      <TravelDeclarationListPage />
                    </Route>
                    <Route path="/travel-declaration/:id" exact>
                      <TravelDeclarationEditPage />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/my-travel-declaration">
                  <Switch>
                    <Route path="/my-travel-declaration" exact>
                      <MyTravelDeclarationsListPage />
                    </Route>
                    <Route path="/my-travel-declaration/:id" exact>
                      <MyTravelDeclarationsUploadPage />
                    </Route>
                  </Switch>
                </Route>

                <Route path="/activity-logs" exact>
                  <ActivityLogListPage />
                </Route>

                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Loader>
          </LoggedInLayout>
        </PrivateRoute>
      </Switch>
    </ErrorBoundary>
  </Router>
);

export default App;
