import React from 'react';
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Dropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import Chip from '@bit/medicalwebexperts.mwe-ui.chip';

const items = [
  {
    label: 'Personal Information',
    to: '/preferences',
    icon: 'person',
  },
  {
    label: 'Password Management',
    to: '/preferences/password-management',
    icon: 'lock',
  },
];

const itemClasses = {
  root: {
    color: 'gray.700',
    textTransform: 'capitalize',
    justifyContent: 'flex-start',
    svg: {
      color: 'primary.light',
    },
    '&.ProfileDropdown--active': {
      color: 'primary.light',
    },
  },
};

const chipClasses = {
  chip: {
    size: '18px',
    minWidth: 0,
    border: '1px solid',
    boderColor: 'white',
  },
};

const ProfileDropdown = () => {
  const { user } = useAuth();
  const dropdown = useDropdownState({ gutter: 5 });

  return (
    <Dropdown state={dropdown}>
      <Dropdown.Toggle
        render={(toggleProps) => (
          <Chip
            {...toggleProps}
            classes={chipClasses}
            content={<Icon as={MdKeyboardArrowDown} size={4} color="white" />}
            position="bottomRight"
            overlap="circle"
            color="success"
          >
            <Avatar name={user.name} size="xs" />
          </Chip>
        )}
      />
      <Dropdown.Menu bg="white" aria-label={user.name}>
        {items.map(({ to, label, icon }) => (
          <Dropdown.Item
            exact
            to={to}
            key={to}
            as={NavLink}
            classes={itemClasses}
            onClick={dropdown.hide}
            activeClassName="ProfileDropdown--active"
          >
            <Icon name={icon} size={19} mr={2} />
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
