export const STATUS = new Map([
  [0, 'Inactive'],
  [false, 'Inactive'],
  [1, 'Active'],
  [true, 'Active'],
]);

export const STATUS_LIST = [
  { value: 1, label: 'Active' },
  { value: 0, label: 'Inactive' },
];

export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  APPLICANT: 'ROLE_APPLICANT',
  EDITOR: 'ROLE_EDITOR',
};

export const ROLES_LABELS = {
  [ROLES.ADMIN]: 'Administrator',
  [ROLES.APPLICANT]: 'Applicant',
  [ROLES.EDITOR]: 'Editor',
};

export const ROLES_LIST = Object.entries(ROLES_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
