import { MdGroup, MdSync, MdAssignment } from 'react-icons/md';

export default [
  {
    label: 'Travel Health Declarations',
    to: '/travel-declaration',
    Icon: MdAssignment,
    action: 'list',
    subject: 'traveler-health-declarations',
    exact: false,
  },
  {
    label: 'My Travel Health Declarations',
    to: '/my-travel-declaration',
    Icon: MdAssignment,
    action: 'complete',
    subject: 'traveler-health-declarations',
    exact: false,
  },
  {
    label: 'Users',
    to: '/users',
    Icon: MdGroup,
    action: 'list',
    subject: 'users',
    exact: false,
  },
  {
    label: 'Activity Logs',
    to: '/activity-logs',
    Icon: MdSync,
    action: 'list',
    subject: 'activity-logs',
  },
];
